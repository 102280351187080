'use client';

import { useState, useEffect } from 'react';

import { usePathname } from 'next/navigation';

import Auth from './Auth';
import Nav from './Nav';

import { Logo, Search } from '@/components';

export default function Header({ type = 'server', className }: { type?: NextComponentType; className?: string }) {
  const pathname = usePathname();

  const [showSearch, setShowSearch] = useState<boolean>(false); // 显示搜索框

  useEffect(() => {
    setShowSearch(!['/about', '/download'].includes(pathname));
  }, [pathname]);

  return (
    <header className={`flex flex-col justify-center items-center w-1200px h-80px z-10 ${className}`}>
      <div className="relative w-full flex justify-between items-center">
        <div className="relative flex items-center h-full">
          <Logo type={type} />
          <Search className={`${showSearch ? 'block' : 'hidden'} ml-40px`} />
        </div>

        <div className="flex items-center h-full">
          <Nav type={type} />
          <Auth />
        </div>
      </div>
    </header>
  );
}
